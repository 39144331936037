import React from "react";
import { useNavigate } from "react-router-dom";

export default function Sidebar() {
  let navigate = useNavigate();
  const handleNavigate = (link) => {
    navigate(link);
  };
  return (
    <>
      <div
        style={{
          background: " rgba(59, 50, 239, 0.7)",
        }}
        className="py-5 h-[100vh] relative  hidden lg:block"
      >
        <p
          onClick={() => handleNavigate("/")}
          className="font-inter font-[700] text-[25px] xl:text-[35px] text-white text-center"
        >
          SigProS
        </p>

        <div className="pl-5  mt-14 xl:mt-14">
          <p
            onClick={() => handleNavigate("/home")}
            className="flex cursor-pointer gap-2 font-[500] text-[10px] xl:text-[13px] text-white hover:text-[#8D6AFF] hover:bg-white p-4 rounded-l-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-3 xl:w-4 h-3 xl:h-4 mt-[1px]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
            {/* Home */}
            Domicile
          </p>

          <p
            onClick={() => handleNavigate("/user-management")}
            className="mt-2 xl:mt-5 flex cursor-pointer gap-2 font-[500] text-[10px] xl:text-[13px] text-white hover:text-[#8D6AFF] hover:bg-white p-4 rounded-l-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-3 xl:w-4 h-3 xl:h-4 mt-[1px]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
              />
            </svg>
            Gestion des utilisateurs
            {/* Users Management */}
          </p>

          <p
            onClick={() => handleNavigate("/benefits-management")}
            className="mt-2 xl:mt-5 flex cursor-pointer gap-2 font-[500] text-[10px] xl:text-[13px] text-white hover:text-[#8D6AFF] hover:bg-white p-4 rounded-l-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-3 xl:w-4 h-3 xl:h-4 mt-[1px]"
              viewBox="0 0 14 15"
              fill="none"
            >
              <path
                d="M11.0833 2.25H2.91667C2.27233 2.25 1.75 2.77233 1.75 3.41667V11.5833C1.75 12.2277 2.27233 12.75 2.91667 12.75H11.0833C11.7277 12.75 12.25 12.2277 12.25 11.5833V3.41667C12.25 2.77233 11.7277 2.25 11.0833 2.25Z"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.83337 4.58331H4.08337V9.83331H5.83337V4.58331Z"
                stroke="white"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.91663 4.58331H8.16663V7.49998H9.91663V4.58331Z"
                stroke="white"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {/* Benefit Management */}
            Gestion des sociaux
          </p>

          <p
            onClick={() => handleNavigate("/report")}
            className="mt-2 xl:mt-5 flex cursor-pointer gap-2 font-[500] text-[10px] xl:text-[13px] text-white hover:text-[#8D6AFF] hover:bg-white p-4 rounded-l-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-3 xl:w-4 h-3 xl:h-4 mt-[1px]"
              viewBox="0 0 14 15"
              fill="none"
            >
              <path
                d="M7 12.1666V6.33331"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.5 12.1666V2.83331"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.5 12.1666V9.83331"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Rapports
          </p>

          <p
            onClick={() => handleNavigate("/setting")}
            className="mt-2 xl:mt-5 flex cursor-pointer gap-2 font-[500] text-[10px] xl:text-[13px] text-white hover:text-[#8D6AFF] hover:bg-white p-4 rounded-l-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-3 xl:w-4 h-3 xl:h-4 mt-[1px]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
            {/* Settings */}
            Paramètres
          </p>
        </div>

        <button className="absolute bottom-10 translate-x-[5%] xl:translate-x-[5%] mx-auto flex gap-2 font-[400] text-[14px] xl:text-[16px] text-white border-[1px] border-white py-2 px-6 xl:px-10  rounded-full hover:bg-white hover:text-[#8D6AFF]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
            />
          </svg>
          {/* Logout */}
          Déconnexion
        </button>
      </div>
    </>
  );
}
