import React from "react";
import Logo from "../assets/svg/Backup_of_EMPREINT KAPITAL logo PNG 1.svg";
import Msg from "../assets/svg/Icons@2x.svg";
import Lock from "../assets/svg/Icons.svg";
import { useNavigate } from "react-router-dom";

export default function Signin() {
  let navigate = useNavigate();
  const handleNavigate = (link) => {
    navigate(link);
  };
  return (
    <>
      <div className="mobile-bg bg">
        <div className="grid lg:grid-cols-2 w-[90%] mx-auto">
          <div>
            <img src={Logo} alt="" className="mx-auto md:mx-0 block" />
          </div>
          <div className="self-center mt-5 md:mt-10">
            <p className="font-inter font-[700] text-[35px] text-white text-center">
              SigProS
            </p>
            <div className="bg-white  rounded-xl  md:w-[60%] lg:w-[80%] mx-auto py-10 mt-10">
              <p className="font-inter font-[700] text-[30px] text-black text-center">
                {/* Login */}
                Connectez-vous
              </p>
              <form className="w-[90%] mx-auto mt-10">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="nom d’utilisateur"
                    className="self-center font-inter font-[500] text-[16px] text-[#1C1C1EB8] border-[1px] border-[#1C1C1EB8] rounded-md py-3 px-10 w-full"
                  />
                  <img src={Msg} alt="" className="absolute top-2 left-2 " />
                </div>

                <div className="relative mt-5">
                  <input
                    type="password"
                    placeholder="mot de passe"
                    className="mt-1 self-center font-inter font-[500] text-[16px] text-[#1C1C1EB8] border-[1px] border-[#1C1C1EB8] rounded-md py-3 px-10 w-full"
                  />
                  <img src={Lock} alt="" className="absolute top-2 left-2 " />
                </div>

                <div className="mt-5 flex justify-between">
                  <div className="flex gap-2">
                    <input type="checkbox" />
                    <p className="font-inter font-[400] text-[#1C1C1E] text-[10px] md:text-[14px]">
                      {/* Remember Me */}
                      souvenez-vous de moi
                    </p>
                  </div>
                  <p className="font-inter font-[600] text-[#0049FC] text-[10px] md:text-[14px]">
                    {/* Forget Password? */}
                    Mot de passe oublié
                  </p>
                </div>
                <button
                  onClick={() => handleNavigate("/home")}
                  className="mt-10 font-inter font-[400]  text-[16px] text-white rounded-md bg-[#0049FC] w-full p-2"
                >
                  {/* Login */}
                  Connectez-vous
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
